<template>
  <!-- <KTLoader v-if="loaderEnabled" :logo="loaderLogo" /> -->
  <router-view></router-view>
</template>
<script>
import KTLoader from "@/components/Loader.vue";
import {
  loaderEnabled,
  loaderLogo
} from "@/core/helpers/config";
export default {
  components: {
    KTLoader,
  },
  data() {
    return {
      pageHandler: true,
    }
  },
  watch: {
    '$store.getters.getIsPageActive': {
      immediate: true,
      handler() {
        this.pageHandler = this.$store.getters.getIsPageActive
      }
    }
  },
  mounted() {
    if (this.pageHandler) {
      return true
    } else {
      this.$router.push('/dashboard')
    }
  }
}
</script>